body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Quicksand", "Roboto", sans-serif;
  /* background: linear-gradient(
    160deg,
    rgba(0, 96, 160, 1) 0%,
    rgba(158, 210, 236, 1) 100%
  ); 
  background: linear-gradient(
    160deg,
    #004b7d 0%,
    #0078c8 27%,
    #008be7 64%, 
    #47abe9 84%,
    #fffdee 100%
  ); 
  background-repeat: no-repeat;
  background-attachment: fixed; */
}
